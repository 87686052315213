declare module "*.png"
import { Button } from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import example from "./example.png"

const FactuurVoorParticulieren = () => (
  <Layout>
    <SEO title="Kwitantie, een factuur voor particulieren?" />
    <div className="page content">
      <h1>Kwitantie een factuur voor particulieren?</h1>
      <img
        src={example}
        style={{
          float: "right",
          width: "15rem",
          boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
          margin: "1rem 0 2rem 2rem",
        }}
      />
      <p>
        Een goede kwitantie maken, is niet moeilijk. Lees eerst aan welke regels
        hij moet voldoen. Onderaan staat een link naar een kwitantie die u zelf
        kunt invullen.
      </p>
      <h2>Wat is een kwitantie?</h2>
      <p>
        Als u als particulier uw diensten of leveranties in rekening wilt
        brengen bij een bedrijf, kunt u geen factuur sturen. Daarop moet immers
        het nummer van de Kamer van Koophandel staan en dat heeft u niet als u
        daar niet staat ingeschreven. De oplossing? Dat is een kwitantie. Net
        als bij een factuur van bedrijf naar bedrijf, zijn er ook eisen aan de
        kwitantie van een privépersoon naar een bedrijf.
      </p>
      <h2>Een kwitantie moet vermelden: </h2>
      <ul>
        <li>Welke goederen en diensten er geleverd zijn</li>
        <li>Hoeveel goederen of diensten er geleverd zijn</li>
        <li>Voor welk bedrag er geleverd is</li>
        <li>Aan wie de kwitantie is gericht</li>
        <li>De datum van de levering van de goederen of diensten</li>
        <li>Uw eigen naam</li>
      </ul>
      <h2>Moet ik op de kwitantie de btw vermelden?</h2>
      <p>
        Omdat u als particulier geen btw-nummer hebt, hoeft u op de kwitantie
        geen btw te berekenen. Het bedrag op de kwitantie is het geldbedrag dat
        u ontvangt.
      </p>
      <h2>Hoe zit het met de belastingen?</h2>
      <p>
        Bij de jaarlijkse aangifte van de inkomstenbelasting moet de particulier
        het totaalbedrag van alle kwitanties opgeven als ‘inkomsten uit overige
        werkzaamheden’. Het is dus belangrijk alle kwitanties goed te bewaren.
        Het bedrijf dat uw kwitanties betaalde, geeft deze post ook op aan de
        Belastingdienst.
      </p>
      <h2>Een gratis kwitantie-app</h2>
      <p>
        Met de gratis kwitantie-app is het mogelijk om snel kwitanties op te
        maken, zonder kosten en zonder boekhouder. Alle verplichte velden staan
        op het formulier. Daarin typt u de benodigde gegevens. Eventueel
        verandert u de rest van de tekst. Daarna kunt u de kwitantie opslaan als
        pdf, printen en versturen per e-mail.
      </p>

      <br />
      <Link to="/" style={{ fontFamily: "Lato" }}>
        <Button variantColor="teal" size="lg">
          Aan de slag: maak zelf een kwitantie.{" "}
        </Button>
      </Link>
    </div>
  </Layout>
)

export default FactuurVoorParticulieren
